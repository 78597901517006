@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .frosted-bg {
    @apply bg-white/45 backdrop-blur-md;
  }
}

/* Apply Poppins to all headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

/* You can leave the code font as is if needed */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
